import React from 'react'

class Error extends React.Component {
  render() {
    return (
      <h1>404</h1>
    )
  }
}

export default Error